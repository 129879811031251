

.form-label {
    display: inline-block;
    max-width: 350px;
	flex-grow: 1;
}

.login-error-div {
    color: #f44336;
    font-size: 0.9rem;
}

.form-input {
    display: inline-block;
}

.form-builder-form-wrapper {
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
}

.form-input {
    width: 250px;
}

.form-input div {
    width: 100%;
}

.MuiFormControl-root textarea {
    min-height: 16rem;
    max-height: 20rem;
    overflow: auto!important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader--moving{
	background: transparent!important;
}


.App {
    min-height: 100%;
    margin: auto;
}
.App {
    box-sizing: border-box;
}


div#root, html, body, .full-container {
    height: 100%;
    overflow: auto;
}

.App {
    display: flex;
	height: 100%;
}

.right-section-bar {
    width: 100%;
    position: relative;
	display: flex;
    flex-direction: column;
}


.navigation-bar {
	background: #222a45f5;
    position: relative;
    width: 260px;
	box-sizing: border-box;
    height: 100%;
    display: flex;
	flex-shrink: 0;
    flex-direction: column;
}

.spacer {
    height: 2rem;
}

.app-header-title{
	padding: 10px;
}

.ecl-header-user-space:hover {
    background: #2a324c1c!important;
}

.ecl-header-user-space {
    background: transparent;
	transition: 0.1s;
	font: inherit!important;
	cursor: pointer!important;
    display: flex;
    flex-shrink: 0!important;
    align-items: center!important;
  /*  margin: 5px!important;*/
    padding: 5px 15px!important;
    border-radius: 30px!important;
}

.ecl-user-icon {
    padding: 0 10px;
    font-size: 2rem;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl.MuiInputBase-multiline.MuiFilledInput-multiline {
    padding-top: 10px;
}

.ecl-grid-container{
	height: 450px;
}
.report-grid-card{
	height: 300px;	
}
.ecl-list-grid-container{
	height: 700px;
}

.app-sub-title.ecl-header-title {
    margin-bottom: 20px;
	
}
.app-header-title.ecl-header-title {
    margin-bottom: 20px;font-weight: 800;	
	width: 100%;
}

.heading-button {
    flex-shrink: 0;
    white-space: nowrap;
}

.ecl-footer {
    background: #2a324c;
    color: white;
    font-size: 0.85rem;
    padding: 0.25rem;
    text-align: center;
}

.ecl-form-builder-wrapper {
    display: flex;
}

.ecl-form-builder-column {
    flex-grow: 1;
    min-width: 400px;
}

.ecl-flex-column{
	display: flex;
}

.ecl-flex-spacer{
	flex-grow: 1;
}


.login-container {
    height: 100%;
    width: 100%;
    background: #222a45f5;
    display: flex;
	justify-content: center;
	align-items: center;
}

.login-form-wrapper {
	min-height: 100px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 6%), 0px 6px 10px 0px rgb(0 0 0 / 4%), 0px 1px 18px 0px rgb(0 0 0 / 4%);
    width: 500px;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;	
    flex-direction: column;
}

.login-form-wrapper {
    padding: 20px;
    box-sizing: border-box;
}

.login-spacer {
    margin: 10px 0;
}


/************* Theme ***************/


.MuiAccordionDetails-root.navbar-details {
    display: block;
	padding: 0;
}

a.navbar-link-flex:hover{
	background: rgba(255, 255, 255, 0.08);
}
.navbar-link.MuiTypography-root {
    display: block;
    line-height: 40px;
    cursor: pointer;
}
a.navbar-link-flex{
    padding: 0 25px;
    padding-right: 7px;
}

a {
    text-decoration: inherit;
    color: inherit;
}

a.link-active {
    color: #81b6f9;
}

div.MuiFilledInput-input ,
input.MuiFilledInput-input {
    padding-top: 10px;
}

body {
    background: #fafafa;
}

.navigation-accorian.MuiPaper-root svg{
	    fill: white;
}
.navigation-accorian.MuiPaper-root{
		background: transparent;
		border: none;
		color: white;
		box-shadow: none;
}

.navigation-accorian .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
}

.navigation-accorian .MuiAccordionSummary-root.Mui-expanded{
	min-height: 0;
}

.navigation-accorian .MuiAccordionSummary-root:hover {
    background: rgba(255, 255, 255, 0.08);
}

.padding-10{
	padding: 0 10px;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
    border-radius: 5px;
}


header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.mui-fixed.MuiPaper-elevation4 {
	background: white;
	color: #757575;
	position: relative;
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.app-header-title-section.mui-fixed.MuiPaper-elevation4 {
    box-shadow: none;
    background: transparent;
    color: white;
	position: relative;
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorPrimary.mui-fixed.MuiPaper-elevation4 {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%);

}

.main-ui-card {
    margin-bottom: 40px;
}


.right-section-contents {
    height: 100%;
    padding: 25px 50px;
	
    box-sizing: border-box;
    overflow: auto;
}

.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded{
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 6%), 0px 6px 10px 0px rgb(0 0 0 / 4%), 0px 1px 18px 0px rgb(0 0 0 / 4%);
	border-radius: 10px;
}

.main-ui-card{
	padding: 25px;
}
.confirm-box-buttons {
    text-align: right;
}

div.ecl-column-radio {
    flex-direction: row;
}

.margin-left {
    margin-left: 10px!important;
}

.variable-grid div.MuiDataGrid-footerContainer {
    display: none!important;
}

.app-logo{
	background: white;
    border-radius: 5px;
    height: 3rem;
    vertical-align: middle;
}

/*********************************/

/**************** Loader CSS **********************/
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #82deff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.ecl-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2a324c87;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.full-container.wait-wrapper {
    position: relative;
}
/****************************************************/

.ecl-notification-container {
    position: fixed;
    bottom: 0;
    height: 0;
    overflow: visible;
    left: 0;
    width: 100%;
}

.ecl-notification-wrapper {
    position: absolute;
    bottom: 20px;
    right: 65px;
}


.ecl-notification-wrapper {
    position: absolute;
    bottom: 30px;
    right: 90px;
}

.ecl-notification {
    margin: 10px 0;
    border-radius: 7px;
    padding: 5px 20px;
    box-shadow: 3px 3px 10px -2px #a5a5a5;
}

.ecl-notification-success {
    background: #a7ffa7;
    border: 1px solid #00e800;
}

.ecl-notification-error {
    background: #ec7474;
    border: 1px solid #e80000;
    color: white;
}

.ecl-fade-in{
  animation-duration: 0.5s;
  animation-name: fade-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.content-heading {
    display: flex;
    align-items: center;
	
    padding-right: 50px;
}

.tooltip-form{
	position: relative;
	padding-right: 30px;
    box-sizing: border-box;
}

.form-input.tooltip-form svg {
    position: absolute;
    top: 8px;
    right: 0;	
    cursor: pointer;

}
/************* Grid **************/
.ecl-status-active {
    line-height: 2rem;
    padding: 0px 10px;
    border-radius: 19px;
    background: #a7ffa7;
    border: 1px solid #00e800;
    box-shadow: 1px 1px 3px -1px #d8d4d4;
}
.ecl-status-inactive {
    line-height: 2rem;
    padding: 0px 10px;
	background: #ec7474;
    border: 1px solid #e80000;
	color: white;
    border-radius: 19px;
    box-shadow: 1px 1px 3px -1px #d8d4d4;
}

.ecl-status-div{
	line-height: 1.5rem;
    padding: 0px 5px;
    border-radius: 9px;
    min-width: 100px;
	text-align: center;
    box-shadow: 1px 1px 3px -1px #d8d4d4;
}

.ecl-status-draft {
	background: #b0f4ff;
    border: 1px solid #00a8e8;
}

.ecl-status-pending {
    background: #ffdca7;
    border: 1px solid #fd8509;
}

.ecl-status-rejected {
	background: #ec7474;
    border: 1px solid #e80000;
	color: white;
}

.ecl-status-approved {
    background: #a7ffa7;
    border: 1px solid #00e800;
}


.MuiDataGrid-columnHeaderWrapper * {
    font-weight: bolder!important;
}

.MuiDataGrid-columnSeparator {
    display: none!important;
}

.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader {
    padding: 0;
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary.card-tabs.MuiPaper-elevation4 {
    background: transparent;
    color: black;
    box-shadow: none;
    margin-bottom: 15px;
    font-weight: 800;
    border-bottom: 1px solid #e6e4e4;
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.card-tabs.MuiPaper-elevation4 button:hover {
	background: #f5f5f5;
}
.card-tabs header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.card-tabs.MuiPaper-elevation4 button {
    font-weight: bolder;    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: 0.3s;
}

.card-tabs span.MuiTabs-indicator {
    background: #2a324c;
}

/***************************/
.card-flex-column {
    display: flex;
}

.card-flex-column > .main-ui-card {
    width: 100%;
}

.card-flex-column > .main-ui-card:not(:first-child) {
    margin-left: 19px;
}

.MuiAccordionDetails-root.navbar-details {
    padding-left: 17px;
    position: relative;
}

.MuiAccordionDetails-root.navbar-details:before {content: ' ';position: absolute;height: 100%;width: 1px;background: #7482af;}

.ecl-confirm-box-container {
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000099;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-box-card {
    width: 550px;
    height: 200px;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 70px;
	
    display: flex;
    flex-direction: column;
}

.confirm-box-contents {
    display: flex;
	height: 100%;
}

.confirm-text {
    width: 100%;
}

.confirm-icon {
    font-size: 0rem;
    padding: 20px;
}

.confirm-icon svg {
    font-size: 4rem;
    color: #ff8d00;
}

.confirm-header {
    font-weight: 800;
    font-size: 1.5rem;
    padding-top: 23px;
}

.tooltip-icon {
    vertical-align: middle;
    position: absolute;
    right: 0;
}

button {
    cursor: pointer;
}

.accordian-link {
    color: white;
}

.accordian-link p.navbar-link {
    padding: 4px 0;
    border-radius: 8px;
}

a.accordian-link.navbar-link-flex {
    padding: 0 18px;
	padding-right: 6px;
}

.ecl-checkbox-col {
    align-items: center;
    justify-content: center!important;
}

.main-ui-card.inline-card {
    display: inline-block;
}

.inline-chart-container {
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
    justify-content: space-evenly;
}

.main-model-chart ul[name="legend-bottom"] {
    flex-direction: row!important;
    display: flex;
}

.main-model-chart #bottom-container {
    justify-content: center;
}

div#pdfDiv {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    bottom: 100%;
    z-index: -16;
}
.MuiAccordion-root.Mui-expanded {
	margin: 0!important;
	margin-bottom: 15px!important;
}

.dashbord-img{
	width: 100%;
}

.report-sub-title {
    font-size: 0.9em;
}

.report-sub-value {
    padding-left: 15px;
    margin-bottom: 15px!important;
}

.MuiDataGrid-root .MuiDataGrid-cell{
	border: none!important;
}

.row-icon-container {
    width: 100%;
    display: flex;
}

.row-label {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.row-icon-container a * {
    font-size: 1rem;
}

.row-icon-container a button {
    padding: 8px;
}

.highlight-icon svg {
    fill: #2060b5;
}

.highlightdownload-icon svg {
    fill: #da1e1e;
}

a.navbar-link-flex {
    display: flex;
}

a.navbar-link-flex > p {
    width: 100%;
}

a.navbar-link-flex button * {
    font-size: 1.25rem;
}

a.accordian-link.navbar-link-flex path {
    fill: white;
}

a.link-active > button path {
    fill: #81b6f9!important;
}

.ecl-view-icon path {
    fill: #0090ff;
}

.ecl-edit-icon path {
    fill: #129612;
}

.ecl-delete-icon path {
    fill: #e23e14;
}

.ecl-header-user-space span {
    text-transform: none;
}

svg.MuiSvgIcon-root.ecl-user-icon {
    padding: 0;
    font-size: 3rem;
}

.app-header-title.ecl-user-title {
    padding: 5px;
}	

.user-flex {
    display: flex;
}

.MuiSvgIcon-root.user-profile-icon {
    font-size: 10rem;
    padding: 15px;
}

.user-div {
    padding: 30px;
}

.user-sub-divs {
    padding: 10px 3px;
}

iframe.admin-iframe {
    height: 100%;
    width: 100%;
    border: none;
    box-sizing: border-box;
}

.right-section-contents.admin-contents {
    padding: 0;
    box-sizing: border-box;
}