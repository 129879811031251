@font-face {
    font-family: AppFont;
    src: url("./Fonts/NunitoSans-Regular.ttf");
    font-weight: 400 ;
}
@font-face {
    font-family: AppFont;
    src: url("./Fonts/NunitoSans-SemiBold.ttf");
    font-weight: 600 ;
}
@font-face {
    font-family: AppFont;
    src: url("./Fonts/NunitoSans-Light.ttf");
    font-weight: 200 ;
}
  

body *{
  font-family: AppFont!important;
}

body {
  margin: 0;
  font-family: AppFont!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
